<template>
  <BaseDialog title="點數設定" width="600px" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <!-- <BaseElFormItem label="選擇點數" prop="shopPointKey">
        <BaseDataSelect
          :value.sync="formData.shopPointKey"
          placeholder="請選擇點數"
          objKey="key"
          :dataList="shopPointList"
        />
      </BaseElFormItem> -->
      <BaseElFormItem prop="mode">
        <template slot="label">
          <FormItemTooltipLabel label="點數發放時機設定" :tooltipWidth="200">
            舉例：如果設定訂單完成後 3 天發放，客人在5/5 完成了一筆訂單，則客人將於 5/8 獲得點數。
          </FormItemTooltipLabel>
        </template>

        <BaseElRadioGroup v-model="formData.mode" class="flex flex-col" style="gap: 16px" @change="onModeChange">
          <BaseElRadio v-if="!hideOptions.includes('IMMEDIATELY')" label="IMMEDIATELY">訂單完成後即時發放</BaseElRadio>
          <div>
            <BaseElRadio label="DELAYED">訂單完成後延遲發放</BaseElRadio>
            <BaseElFormItem prop="days" style="margin-top: 8px">
              <div class="flex items-center" style="margin-left: 25px; gap: 20px">
                <span>訂單完成後</span>
                <BaseElInput v-model="formData.days" style="width: 100px" placeholder="請輸入" />
                <span>天發放</span>
              </div>
            </BaseElFormItem>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, computed, watch } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { UpdateRewardActivityDelaySendSetting, UpdateRewardActivity } from '@/api/rewardActivity'
import store from '@/store'
import { isDigitRules, noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { get } from 'lodash'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberPointConfigEditModal',
  components: {
    BaseDialog, FormItemTooltipLabel,
    // BaseDataSelect
  },
  props: {
    configData: { type: Object, default: () => ({}) },
    source: { type: String, default: '' },
    rewardType: { type: String, default: '' },
    hideOptions: { type: Array, default: () => [] },
    hideShopPointEdit: { type: Boolean, default: false }, // 暫時性，因為第三方交易點數設定尚未開發完成
  },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const { shopPointList } = useShop()
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      mode: 'IMMEDIATELY',
      days: null,
      shopPointKey: null,
    })
    const formRules = computed(() => {
      const rules = { mode: [noEmptyRules()] }

      if (formData.mode === 'DELAYED') {
        rules.days = [
          noEmptyRules(),
          isDigitRules(),
        ]
      }

      return rules
    })

    const compactPostData = computed(() => {
      const { mode, days } = formData
      return {
        shopId: shopId.value,
        source: props.source,
        rewardType: props.rewardType,
        mode,
        delayDays: mode === 'IMMEDIATELY' ? 0 : Number(days),
        sendOffsetHours: 0,
        // shopPointKey: get(formData.shopPointKey, 'key'),
      }
    })

    watch(props.configData, () => {
      syncData()
    })

    const updateMemberShopInvoiceConfig = async (payload) => {
      const [, err] = await UpdateRewardActivityDelaySendSetting(payload)
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
    }

    const syncData = () => {
      const config = props.configData
      formData.mode = get(config, 'mode')
      formData.days = get(config, 'delayDays')
      // formData.shopPointKey = get(config, 'shopPointKey')
    }

    const onModeChange = (mode) => {
      formUtils.clearValidate(formRef.value)
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      await updateMemberShopInvoiceConfig(compactPostData.value)
      loading.value = false
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      syncData()
    })

    return { formRef, formData, formRules, onConfirm, onModeChange, shopPointList, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
